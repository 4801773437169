import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import DeviceList from '@/views/device/DeviceList';
import Device from '@/views/device/Device';
import store from "@/store";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: window.__POWERED_BY_QIANKUN__ ? '/service/inventory/' : '/',
    routes: [
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/', component: DeviceList, meta: {title: 'Список устройств'}},
        {path: '/create', component: Device, meta: {title: 'Создание устройства'}},
        {path: '/:id', component: Device, meta: {title: 'Просмотр устройства'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/' !== to.path) {
        return next('/');
    }
    if (!store.getters.isAdmin && '/create' === to.path) {
        return next('/');
    }
    return next();
});

export default router;
