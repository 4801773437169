<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div v-if="isAdmin" class="d-flex">
      <router-link to="/create" class="btn btn-primary mb-2">Создать устройство</router-link>
    </div>
    <b-table
        hover
        :items="getDeviceDataTable"
        :fields="fields"
        :current-page="deviceList.currentPage"
        ref="device_table"
        :per-page="deviceList.perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Устройства отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ '0x' + Number(row.item.id).toString(16).toUpperCase() }}
      </template>
      <template v-slot:cell(name)="row">
        {{ row.item.name }}
      </template>
      <template v-slot:cell(description)="row">
        {{ row.item.description }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button class="" variant="primary"
                  :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                  @click="prepareDetailPage(row.item.id)">
          <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
          <b-icon-eye v-else font-scale="0.75"></b-icon-eye>
        </b-button>
        <b-button class="ml-1" variant="primary"
                  v-if="isAdmin"
                  title="Удалить"
                  @click="deleteDevice(row.item.id)">
          <b-icon-trash font-scale="0.75"></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="deviceList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="deviceList.currentPage"
          class="my-0 ml-auto"
          :total-rows="deviceList.totalCount"
          :per-page="deviceList.perPage"
          aria-controls="template-table"
          @input="getDeviceDataTable">
      </b-pagination>
    </div>
  </div>
</template>
<script>
import deviceApi from '../../modules/inventory';
import {mapGetters} from "vuex";

export default {
  name: 'device-list',
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id', label: 'Идентификатор', sortable: true
        },
        {
          key: 'name', label: 'Название', sortable: true
        },
        {
          key: 'description', label: 'Описание', sortable: true
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      deviceList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isRolesEmpty"
    ])
  },
  methods: {
    getDeviceDataTable(context) {
      return deviceApi.getDeviceList(context.perPage, context.currentPage, context.sortBy,
          context.sortDesc ? "Descending" : "Ascending")
          .catch((error) => {
            let errorMessage;
            if (error.isAxiosError) {
              errorMessage = `Ошибка при запросе списка устройств (код ${error.response?.status})`;
            } else {
              errorMessage = 'Неизвестная ошибка при запросе списка устройств';
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          })
          .then(resp => {
            this.deviceList.totalCount = resp.data.totalCount;
            return resp.data.content;
          });
    },
    deleteDevice(id) {
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить устройство?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              deviceApi.deleteDevice(id)
                  .then(() => {
                    return this.$refs.device_table.refresh();
                  })
                  .catch(error => {
                    this.$bvToast.toast(error.response?.status === 403 ?
                        'Недостаточно прав на удаление устройств' :
                        error.response?.status === 404 ?
                            'Устройство не найдено' :
                            'Возникла неизвестная ошибка при удалении', {
                      title: 'Ошибка',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  });
            }
          });
    },
    prepareDetailPage(id) {
      this.$router.push(`/${id}`);
    }
  }
}
</script>
